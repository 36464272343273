
import React from "react";
import "./AboutUs.css";
import Image from "../Assets/Image_cart.jpg";

const AboutUs = () => {
  return (
    // <section className="bg-gradient-to-b from-purple-500 to-pink-400 py-16 px-4 sm:px-6 lg:px-8 w-full md:w-4/5 mx-auto">
    <div className="about">
      <h1 className="about-title">ABOUT US</h1>
      <hr/>
      <div className="about-container">
        <div className="about-content">
          <p className="about-text">
            Welcome to <strong>ShopEase</strong>, your number one source for all
            things fashion. We're dedicated to giving you the very best of
            clothing, with a focus on quality, customer service, and uniqueness.
          </p>
          <p className="about-text">
            Founded in 2020, <strong>ShopEase</strong> has come a long way from
            its beginnings in a small home office. When we first started out,
            our passion for eco-friendly and affordable clothing drove us to
            start our own business.
          </p>
          <p className="about-text">
            We hope you enjoy our products as much as we enjoy offering them to
            you. If you have any questions or comments, please don't hesitate to
            contact us.
          </p>
          <p className="about-signature">
            Sincerely,
            <br />
            <strong>John Doe</strong>
            <br />
            Founder, Kraafty
          </p>
        </div>
        <div className="about-image">
          <img src={Image} alt="About ShopEase" />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
