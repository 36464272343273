// import React from "react";
// import { Link } from "react-router-dom";
// import blogs from "./blogsData";
// import "./CSS/BlogPage.css";

// const BlogPage = () => {
//   return (
//     <div className="blog-container">
//       {blogs.map((blog) => (
//         <div key={blog.id} className="blog-card">
//           <div className="blog-image-wrapper">
//             <img src={blog.image} alt={blog.title} className="blog-image" />
//           </div>
//           <div className="blog-content">
//             <h2 className="blog-title">{blog.title}</h2>
            
//             {/* <p className="blog-description">{blog.description}</p> */}
//             <div className="link-box">
//               <Link to={`/blog/${blog.id}`} className="blog-read-more">
//                 Read More...
//               </Link>
              
//               <a
//                 href={blog.product_link}
//                 className="blog-product-link"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 Buy
//               </a>
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default BlogPage;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./CSS/BlogPage.css";

const BlogPage = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch("https://ecommerce-backend-mogr.onrender.com/api/get-all-blogs/");
        const data = await response.json();
        setBlogs(data.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div className="blog-container">
      {blogs.map((blog) => (
        <div key={blog.id} className="blog-card">
          <div className="blog-image-wrapper">
            <img src={blog.image} alt={blog.title} className="blog-image" />
          </div>
          <div className="blog-content">
            <h2 className="blog-title">{blog.title}</h2>
            <div className="link-box">
              <Link to={`/blog/${blog.id}`} className="blog-read-more">
                Read More...
              </Link>
              <a
                href={`https://${blog.product_link}`}
                className="blog-product-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Buy
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogPage;
