// import React, { useContext, useRef, useState} from 'react';
// import './Navbar.css';
// import logo1 from '../Assets/logo1.PNG';
// import cart_icon from '../Assets/cart_icon.png';
// import nav_dropdown from '../Assets/nav_dropdown.png';
// import { Link } from 'react-router-dom';
// import { ShopContext } from '../../Context/ShopContext';

// const Navbar = () => {

//   const [menu, setMenu] = useState("shop");
//   const {getTotalCartItems } = useContext(ShopContext);
//   const menuRef = useRef();

//   const dropdown_toggle = (e) => {
//     menuRef.current.classList.toggle('nav-menu-visible');
//     e.target.classList.toggle('open');
//   }

//   return (
//     <div className='navbar'>
//       <Link to='/' onClick={() => { setMenu("shop") }} className="nav-logo">
//         <img src={logo1} alt="Logo" />
//       </Link>
//       <img onClick={dropdown_toggle} className='nav-dropdown' src={nav_dropdown} alt="Menu" />

//       <ul ref={menuRef} className="nav-menu">
//         <li onClick={() => { setMenu("Home") }}><Link to='/'>Home</Link>{menu === "Home" ? <hr /> : <></>}</li>
//         <li onClick={() => { setMenu("womens") }}><Link to="/womens">Women</Link>{menu === "womens" ? <hr /> : <></>}</li>
//         <li onClick={() => { setMenu("home_decor") }}><Link to='/home_decor'>Home Decor</Link>{menu === "home_decor" ? <hr /> : <></>}</li>
//         <li onClick={() => { setMenu("Blog") }}><Link to='/Blog'>Blog</Link>{menu === "Blog" ? <hr /> : <></>}</li>
//         <li onClick={()=>{setMenu("add items")}}><Link to='/add_items'>Add Items</Link>{menu==="add items"?<hr/>:<></>}</li>
//       </ul>

//       <div className="nav-login-cart">
//         <Link to='/Signup'><button>Login</button></Link>
//         <Link to='/cart'><img src={cart_icon} alt="Cart" /></Link>
//         <div className="nav-cart-count">{getTotalCartItems()}</div>
//       </div>
//     </div>
//   );
// }

// export default Navbar;

import React, { useContext, useRef, useState, useEffect } from 'react';
import './Navbar.css';
import logo1 from '../Assets/logo1.PNG';
import cart_icon from '../Assets/cart_icon.png';
import nav_dropdown from '../Assets/nav_dropdown.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import { ShopContext } from '../../Context/ShopContext';
import { useNavigate } from 'react-router';

const Navbar = ({isHomePage}) => {
  const [menu, setMenu] = useState("shop");
  const { clearCartItems, cartItems, getTotalCartItems } = useContext(ShopContext);
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token')); // Initialize with token presence
  const [scrollUp, setScrollUp] = useState(true);
  const menuRef = useRef();
  const Navigate = useNavigate();
  const lastScrollY = useRef(window.scrollY);

  const dropdown_toggle = (e) => {
    menuRef.current.classList.toggle('nav-menu-visible');
    e.target.classList.toggle('open');
  };

  // Listen for token changes in localStorage to detect login/logout
  useEffect(() => {
    const handleStorageChange = () => {
      const token = localStorage.getItem('token');
      if (token) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // Recalculate cart count whenever cartItems changes
  useEffect(() => {
    getTotalCartItems();
  }, [cartItems]);

  // Logout function to remove token
  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    // Optionally, you can also trigger a reload to ensure full state reset
    // window.location.reload();
    clearCartItems();
    Navigate('/');
  };

   // Scroll listener to hide/show Navbar based on scroll direction
   useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY.current && currentScrollY > 100) {
        // Scrolling down and past 100px
        setScrollUp(false);
      } else {
        // Scrolling up or at the top
        setScrollUp(true);
      }

      lastScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`navbar ${scrollUp ? 'visible' : 'hidden'} ${isHomePage ? 'home' : 'not-home'}`}>
      <Link to='/' onClick={() => { setMenu("shop") }} className="nav-logo">
        <img src="4[1].png" alt="Logo"
        //  style={{height:"110px"}}
         />
      </Link>
      <img onClick={dropdown_toggle} className='nav-dropdown' src={nav_dropdown} alt="Menu" />

      <ul ref={menuRef} className="nav-menu">
        <li onClick={() => { setMenu("Home") }}><Link to='/'>Home</Link>{menu === "Home" ? <hr /> : <></>}</li>
        <li onClick={() => { setMenu("womens") }}><Link to="/womens">Women</Link>{menu === "womens" ? <hr /> : <></>}</li>
        <li onClick={() => { setMenu("home_decor") }}><Link to='/home_decor'>Home Decor</Link>{menu === "home_decor" ? <hr /> : <></>}</li>
        <li onClick={() => { setMenu("garden_decor") }}><Link to='/garden_decor'>Garden Decor</Link>{menu === "garden_decor" ? <hr /> : <></>}</li>
        <li onClick={() => { setMenu("Blog") }}><Link to='/Blog'>Blog</Link>{menu === "Blog" ? <hr /> : <></>}</li>
        {/* <li onClick={() => { setMenu("add items") }}><Link to='/add_items'>Add Items</Link>{menu === "add items" ? <hr /> : <></>}</li> */}
      </ul>

      <div className="nav-login-cart">
        {/* Conditionally render Login button or FontAwesome user icon */}
        {!isAuthenticated ? (
          <Link to='/Login'><button>Login</button></Link>
        ) : (
          <div className="profile-dropdown">
            <FontAwesomeIcon icon={faUser} style={{ color: "#000000", fontSize: '24px' }} />
            <div className="dropdown-content">
              <button onClick={handleLogout}>Logout</button>
            </div>
          </div>
        )}
        <Link to='/cart'><img src={cart_icon} alt="Cart" /></Link>
        <div className="nav-cart-count">{getTotalCartItems()}</div>
      </div>
    </div>
  );
};

export default Navbar;



