import React, { useEffect, useState } from 'react';
import './Popular.css';
import Item from '../Item/Item';
import axios from 'axios';

const Popular = () => { 
  const [popularProductData, setPopularProductData] = useState([]);

  useEffect(() => {
    async function getData() {
      try {
        // const response = await axios.get("https://ecommerce-backend-iota-weld.vercel.app/api/get-top-selling-product/");
        const response = await axios.get("https://ecommerce-backend-mogr.onrender.com/api/get-top-selling-product/");
        console.log("data", response.data.data);
        setPopularProductData(response.data.data);
      } catch (error) {
        console.log("error", error);
      }
    }
    getData();
  }, []);

  return (
    <div className='popular'>
      <h1>POPULAR IN WOMEN</h1>
      <hr />
      <div className="popular-item">
        {popularProductData
        .filter(item => item.top_selling && item.is_active)

        .map((item) => (
          <Item 
            key={item.id} 
            id={item.id} 
            name={item.product_name} 
            image={item.main_image} 
            new_price={item.offer_price} 
            old_price={item.mrp}
            rating={item.rating}
          />
        ))}
      </div>
    </div>
  );
};

export default Popular;
