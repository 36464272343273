import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ProductDisplay.css";
import star_icon from "../Assets/star_icon.png";
import star_dull_icon from "../Assets/star_dull_icon.png";
import { ShopContext } from "../../Context/ShopContext";
import DeliverySection from "../DeliverySection/DeliverySection";
import Notification from "../Notification/Notification";

const ProductDisplay = ({ product, selectedSize, onSizeChange }) => {
  const navigate = useNavigate();
  const { addToCart } = useContext(ShopContext); // Use addToCart from context
  const [zoom, setZoom] = useState({
    show: false,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [selectedImage, setSelectedImage] = useState(product.main_image); // Handle selected image
  const [quantity, setQuantity] = useState(1); // Handle product quantity
  const [isNotificationVisible, setNotificationVisible] = useState(false);

  const allSizes = ["S", "M", "L", "XL", "XXL"];
  const availableSizes = product.size || [];

  // Check if the size is disabled
  const isDisabled = (size) => !availableSizes.includes(size);

  // Handle size click
  const handleSizeClick = (size) => {
    if (!isDisabled(size)) {
      onSizeChange(size); // Call onSizeChange when size is clicked
    }
  };

  // Handle zoom on hover
  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const width = rect.width;
    const height = rect.height;

    setZoom({ show: true, x, y, width, height });
  };

  // Hide zoom on mouse leave
  const handleMouseLeave = () => {
    setZoom({ show: false, x: 0, y: 0, width: 0, height: 0 });
  };

  // Handle image click to change selected image
  const handleImageClick = (media) => {
    setSelectedImage(media);
  };

  const isImage = (src) => /\.(jpg|jpeg|png|gif)$/i.test(src);

  // Function to handle adding the product to the cart
  const handleAddToCart = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login");
      return;
    }

    if (!selectedSize) {
      alert("Please select a size.");
      return;
    }

    // Call the addToCart function from context
    addToCart(
      product.id,
      selectedSize,
      quantity,
      product.offer_price,
      product.mrp
    );
    setNotificationVisible(true);
  };

  // Increase quantity
  const increaseQuantity = () => setQuantity(quantity + 1);

  // Decrease quantity
  const decreaseQuantity = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  return (
    <div className="productdisplay">
      <div className="productdisplay-left">
        <div className="productdisplay-img-list">
          <img
            src={product.main_image}
            alt=""
            onClick={() => handleImageClick(product.main_image)}
          />
          {product.second_media && (
            <img
              src={product.second_media}
              alt=""
              onClick={() => handleImageClick(product.second_media)}
            />
          )}
          {product.third_media && (
            <img
              src={product.third_media}
              alt=""
              onClick={() => handleImageClick(product.third_media)}
            />
          )}
        </div>
        <div className="productdisplay-centre">
          <div
            className="productdisplay-img"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          >
            {isImage(selectedImage) && (
              <>
                <img
                  className="productdisplay-main-img"
                  src={selectedImage}
                  alt="main product"
                />
                {zoom.show && (
                  <div
                    className="productdisplay-zoomed-img"
                    style={{
                      backgroundImage: `url(${selectedImage})`,
                      backgroundPosition: `${-zoom.x * 0.3}px ${
                        -zoom.y * 0.3
                      }px`,
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="productdisplay-right">
        <h1>{product.product_name}</h1>
        <div className="productdisplay-right-stars">
          {[...Array(5)].map((_, i) => (
            <img
              key={i}
              src={i < product.rating ? star_icon : star_dull_icon}
              alt="stars"
            />
          ))}
          <p>({product.rating})</p>
        </div>
        <div className="productdisplay-right-prices">
          <div className="productdisplay-right-price-old">${product.mrp}</div>
          <div className="productdisplay-right-price-new">
            ${product.offer_price}
          </div>
        </div>
        <div className="productdisplay-right-description">
          {product.description}
        </div>
        <div className="productdisplay-right-quantity">
          <p>Available Quantity: {product.quantity}</p>
        </div>
        <div>
          <DeliverySection />
        </div>
        <div className="productdisplay-right-size">
          <h1>Select Size</h1>
          <div className="productdisplay-right-sizes">
            {allSizes.map((size, i) => (
              <div
                key={i}
                className={`size-option ${isDisabled(size) ? "disabled" : ""} ${
                  size === selectedSize ? "selected" : ""
                }`}
                onClick={() => handleSizeClick(size)}
              >
                {size}
              </div>
            ))}
          </div>
          <div className="order-container">
            <h1>Order Quantity :</h1>
            <div className="order-quantity">
              <button className="order-quantity-btn" onClick={decreaseQuantity}>
                -
              </button>
              <input
                type="number"
                className="order-quantity-input"
                value={quantity}
                readOnly
              />
              <button className="order-quantity-btn" onClick={increaseQuantity}>
                +
              </button>
            </div>
          </div>
        </div>
        <button className="add-to-cart-button" onClick={handleAddToCart}>
          ADD TO CART
        </button>
        <Notification
          message="Your order added into the cart"
          isVisible={isNotificationVisible}
          onClose={() => setNotificationVisible(false)}
        />
        <p className="productdisplay-right-category">
          <span>Category :</span> {product.category_name}
        </p>
        <p className="productdisplay-right-category">
          <span>Tags :</span> Modern, Latest
        </p>
      </div>
    </div>
  );
};

export default ProductDisplay;

// import React, { useContext, useState, useEffect } from "react";
// import { useNavigate } from 'react-router-dom';
// import "./ProductDisplay.css";
// import star_icon from "../Assets/star_icon.png";
// import star_dull_icon from "../Assets/star_dull_icon.png";
// import { ShopContext } from '../../Context/ShopContext';
// import DeliverySection from '../DeliverySection/DeliverySection';

// const ProductDisplay = ({ product }) => {
//   const navigate = useNavigate();
//   const { addToCart } = useContext(ShopContext);  // Use addToCart from context
//   const [zoom, setZoom] = useState({
//     show: false,
//     x: 0,
//     y: 0,
//     width: 0,
//     height: 0,
//   });
//   const [selectedImage, setSelectedImage] = useState(product.main_image);

//   // Set default selected size to 'S' if available
//   const availableSizes = product.size || [];
//   const defaultSize = availableSizes.includes("S") ? "S" : null;
//   const [selectedSize, setSelectedSize] = useState(defaultSize);

//   const [quantity, setQuantity] = useState(1);

//   const allSizes = ["S", "M", "L", "XL", "XXL"];

//   const isDisabled = (size) => !availableSizes.includes(size);

//   const handleSizeClick = (size) => {
//     if (!isDisabled(size)) {
//       setSelectedSize(size === selectedSize ? null : size);
//     }
//   };

//   const handleMouseMove = (e) => {
//     const rect = e.currentTarget.getBoundingClientRect();
//     const x = e.clientX - rect.left;
//     const y = e.clientY - rect.top;
//     const width = rect.width;
//     const height = rect.height;

//     setZoom({ show: true, x, y, width, height });
//   };

//   const handleMouseLeave = () => {
//     setZoom({ show: false, x: 0, y: 0, width: 0, height: 0 });
//   };

//   const handleImageClick = (media) => {
//     setSelectedImage(media);
//   };

//   const isImage = (src) => /\.(jpg|jpeg|png|gif)$/i.test(src);

//   // Function to handle adding the product to the cart
//   const handleAddToCart = () => {
//     const token = localStorage.getItem('token');

//     if (!token) {
//       navigate('/login');
//       return;
//     }

//     if (!selectedSize) {
//       alert("Please select a size.");
//       return;
//     }

//     // Call the addToCart function from context
//     addToCart(product.id, selectedSize, quantity, product.offer_price, product.mrp);
//   };

//   const increaseQuantity = () => {
//     setQuantity(quantity + 1);
//   };

//   const decreaseQuantity = () => {
//     if (quantity > 1) {
//       setQuantity(quantity - 1);
//     }
//   };

//   return (
//     <div className="productdisplay">
//       <div className="productdisplay-left">
//         <div className="productdisplay-img-list">
//           <img
//             src={product.main_image}
//             alt=""
//             onClick={() => handleImageClick(product.main_image)}
//           />
//           {product.second_media && (
//             <img
//               src={product.second_media}
//               alt=""
//               onClick={() => handleImageClick(product.second_media)}
//             />
//           )}
//           {product.third_media && (
//             <img
//               src={product.third_media}
//               alt=""
//               onClick={() => handleImageClick(product.third_media)}
//             />
//           )}
//           {product.fourth_media && (
//             <img
//               src={product.fourth_media}
//               alt=""
//               onClick={() => handleImageClick(product.fourth_media)}
//             />
//           )}
//           {product.fifth_media && (
//             <img
//               src={product.fifth_media}
//               alt=""
//               onClick={() => handleImageClick(product.fifth_media)}
//             />
//           )}
//         </div>
//         <div className="productdisplay-centre">
//           <div
//             className="productdisplay-img"
//             onMouseMove={handleMouseMove}
//             onMouseLeave={handleMouseLeave}
//           >
//             {isImage(selectedImage) && (
//               <>
//                 <img
//                   className="productdisplay-main-img"
//                   src={selectedImage}
//                   alt="main product"
//                 />
//                 {zoom.show && (
//                   <div
//                     className="productdisplay-zoomed-img"
//                     style={{
//                       backgroundImage: `url(${selectedImage})`,
//                       backgroundPosition: `${-zoom.x * 0.3}px ${-zoom.y * 0.3}px`,
//                     }}
//                   />
//                 )}
//               </>
//             )}
//           </div>
//         </div>
//       </div>

//       <div className="productdisplay-right">
//         <h1>{product.product_name}</h1>
//         <div className="productdisplay-right-stars">
//           {[...Array(5)].map((_, i) => (
//             <img
//               key={i}
//               src={i < product.rating ? star_icon : star_dull_icon}
//               alt="stars"
//             />
//           ))}
//           <p>({product.rating})</p>
//         </div>
//         <div className="productdisplay-right-prices">
//           <div className="productdisplay-right-price-old">
//             ${product.mrp}
//           </div>
//           <div className="productdisplay-right-price-new">
//             ${product.offer_price}
//           </div>
//         </div>
//         <div className="productdisplay-right-description">
//           {product.description}
//         </div>
//         <div className="productdisplay-right-quantity">
//           <p>Available Quantity: {product.quantity}</p>
//         </div>
//         <div><DeliverySection/></div>
//         <div className="productdisplay-right-size">
//           <h1>Select Size</h1>
//           <div className="productdisplay-right-sizes">
//             {allSizes.map((size, i) => (
//               <div
//                 key={i}
//                 className={`size-option ${isDisabled(size) ? 'disabled' : ''} ${
//                   size === selectedSize ? 'selected' : ''
//                 }`}
//                 onClick={() => handleSizeClick(size)}
//               >
//                 {size}
//               </div>
//             ))}
//           </div>

//           <div className="order-container">
//             <h1>Order Quantity : </h1>
//             <div className="order-quantity">
//               <button className="order-quantity-btn" onClick={decreaseQuantity}>-</button>
//               <input
//                 type="number"
//                 className="order-quantity-input"
//                 value={quantity}
//                 readOnly
//               />
//               <button className="order-quantity-btn" onClick={increaseQuantity}>+</button>
//             </div>
//           </div>
//         </div>
//         <button className="add-to-cart-button" onClick={handleAddToCart}>
//           ADD TO CART
//         </button>

//         <p className="productdisplay-right-category">
//           <span>Category :</span> {product.category_name}
//         </p>
//         <p className="productdisplay-right-category">
//           <span>Tags :</span>Modern, Latest
//         </p>
//       </div>
//     </div>
//   );
// };

// export default ProductDisplay;

// import React, { useContext, useState,useEffect } from "react";
// import { useNavigate } from 'react-router-dom';
// import "./ProductDisplay.css";
// import star_icon from "../Assets/star_icon.png";
// import star_dull_icon from "../Assets/star_dull_icon.png";
// import { ShopContext } from '../../Context/ShopContext';
// import DeliverySection from '../DeliverySection/DeliverySection';

// const ProductDisplay = ({ product }) => {
//   const navigate = useNavigate();
//   const { addToCart } = useContext(ShopContext);  // Use addToCart from context
//   const [zoom, setZoom] = useState({
//     show: false,
//     x: 0,
//     y: 0,
//     width: 0,
//     height: 0,
//   });
//   const [selectedImage, setSelectedImage] = useState(product.main_image);
//   const [selectedSize, setSelectedSize] = useState(null);
//   const [quantity, setQuantity] = useState(1);

//   const availableSizes = product.size || [];
//   const allSizes = ["S", "M", "L", "XL", "XXL"];

//   const isDisabled = (size) => !availableSizes.includes(size);

//   const handleSizeClick = (size) => {
//     if (!isDisabled(size)) {
//       setSelectedSize(size === selectedSize ? null : size);
//     }
//   };

//   const handleMouseMove = (e) => {
//     const rect = e.currentTarget.getBoundingClientRect();
//     const x = e.clientX - rect.left;
//     const y = e.clientY - rect.top;
//     const width = rect.width;
//     const height = rect.height;

//     setZoom({ show: true, x, y, width, height });
//   };

//   const handleMouseLeave = () => {
//     setZoom({ show: false, x: 0, y: 0, width: 0, height: 0 });
//   };

//   const handleImageClick = (media) => {
//     setSelectedImage(media);
//   };

//   const isImage = (src) => /\.(jpg|jpeg|png|gif)$/i.test(src);

//   // Function to handle adding the product to the cart
//   const handleAddToCart = () => {
//     const token = localStorage.getItem('token');

//     if (!token) {
//       navigate('/login');
//       return;
//     }

//     if (!selectedSize) {
//       alert("Please select a size.");
//       return;
//     }

//     // Call the addToCart function from context
//     addToCart(product.id, selectedSize, quantity);
//     alert('Product successfully added to cart!');
//   };

//   const [showFirstMarquee, setShowFirstMarquee] = useState(true);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setShowFirstMarquee(false);
//     }, );

//     return () => clearTimeout(timer); // Cleanup timer on unmount
//   }, []);
//   const increaseQuantity = () => {
//     setQuantity(quantity + 1);
//   };

//   const decreaseQuantity = () => {
//     if (quantity > 1) {
//       setQuantity(quantity - 1);
//     }
//   };

//   return (
//     <div className="productdisplay">
//       <div className="productdisplay-left">
//         <div className="productdisplay-img-list">
//           <img
//             src={product.main_image}
//             alt=""
//             onClick={() => handleImageClick(product.main_image)}
//           />
//           {product.second_media && (
//             <img
//               src={product.second_media}
//               alt=""
//               onClick={() => handleImageClick(product.second_media)}
//             />
//           )}
//           {product.third_media && (
//             <img
//               src={product.third_media}
//               alt=""
//               onClick={() => handleImageClick(product.third_media)}
//             />
//           )}
//           {product.fourth_media && (
//             <img
//               src={product.fourth_media}
//               alt=""
//               onClick={() => handleImageClick(product.fourth_media)}
//             />
//           )}
//           {product.fifth_media && (
//             <img
//               src={product.fifth_media}
//               alt=""
//               onClick={() => handleImageClick(product.fifth_media)}
//             />
//           )}
//         </div>
//         <div className="productdisplay-centre">
//           <div
//             className="productdisplay-img"
//             onMouseMove={handleMouseMove}
//             onMouseLeave={handleMouseLeave}
//           >
//             {isImage(selectedImage) && (
//               <>
//                 <img
//                   className="productdisplay-main-img"
//                   src={selectedImage}
//                   alt="main product"
//                 />
//                 {zoom.show && (
//                   <div
//                     className="productdisplay-zoomed-img"
//                     style={{
//                       backgroundImage: `url(${selectedImage})`,
//                       backgroundPosition: `${-zoom.x * 0.3}px ${-zoom.y * 0.3}px`,
//                     }}
//                   />
//                 )}
//               </>
//             )}
//           </div>
//         </div>
//       </div>

//       <div className="productdisplay-right">
//         <h1>{product.product_name}</h1>
//         <div className="productdisplay-right-stars">
//           {[...Array(5)].map((_, i) => (
//             <img
//               key={i}
//               src={i < product.rating ? star_icon : star_dull_icon}
//               alt=""
//             />
//           ))}
//           <p>({product.rating})</p>
//         </div>
//         <div className="productdisplay-right-prices">
//           <div className="productdisplay-right-price-old">
//             ${product.mrp}
//           </div>
//           <div className="productdisplay-right-price-new">
//             ${product.offer_price}
//           </div>
//         </div>
//         <div className="productdisplay-right-description">
//           {product.description}
//         </div>
//         <div className="productdisplay-right-quantity">
//           <p>Available Quantity: {product.quantity}</p>
//         </div>
//         <div><DeliverySection/></div>
//         <div className="productdisplay-right-size">
//           <h1>Select Size</h1>
//           <div className="productdisplay-right-sizes">
//             {allSizes.map((size, i) => (
//               <div
//                 key={i}
//                 className={`size-option ${isDisabled(size) ? 'disabled' : ''} ${
//                   size === selectedSize ? 'selected' : ''
//                 }`}
//                 onClick={() => handleSizeClick(size)}
//               >
//                 {size}
//               </div>
//             ))}
//           </div>

//           <div className="order-container">
//             <h1>Order Quantity : </h1>
//             <div className="order-quantity">
//               <button className="order-quantity-btn" onClick={decreaseQuantity}>-</button>
//               <input
//                 type="number"
//                 className="order-quantity-input"
//                 value={quantity}
//                 readOnly
//               />
//               <button className="order-quantity-btn" onClick={increaseQuantity}>+</button>
//             </div>
//           </div>
//         </div>
//         <button className="add-to-cart-button" onClick={handleAddToCart}>
//           ADD TO CART
//         </button>
//         {/* <marquee direction="down" scrolldelay="3"><p>hello</p></marquee> */}

//         {/* <div>
//           {showFirstMarquee ? (
//             <marquee direction="left" scrolldelay="3">
//               <p>Hello, this is the first marquee!</p>
//             </marquee>
//           ) : (
//             <marquee direction="left" scrolldelay="3">
//               <p>Hello, this is the second marquee!</p>
//             </marquee>
//           )}
//         </div> */}

//         <p className="productdisplay-right-category">
//           <span>Category :</span> {product.category_name}
//         </p>
//         <p className="productdisplay-right-category">
//           <span>Tags :</span>Modern, Latest
//         </p>
//       </div>
//     </div>
//   );
// };

// export default ProductDisplay;
