import "./App.css";
// import './Components/Navbar/Navbar.css'
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Shop from './Pages/Shop';
import ShopCategory from './Pages/ShopCategory';
import Product from './Pages/Product';
import Cart from './Pages/Cart';
import Signup from './Pages/Signup';
import Login from './Components/Login/Login';
import Footer from './Components/Footer/Footer';
import men_banner from './Components/Assets/banner_mens.png'
import women_banner from './Components/Assets/banner_women.png'
import kid_banner from './Components/Assets/banner_kids.png'
import BlogDetail from './Pages/Blog/BlogDetail';
import BlogPage from './Pages/Blog/BlogPage';
import Scroller from './Components/Scroller/Scroller';
import { useLocation } from 'react-router-dom';
import AddItems from './Pages/AddItems';
import ScrollingUp from './Components/ScrollingUp/ScrollingUp';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from '@vercel/speed-insights/react';

const Layout = ({ children }) => {
  const location = useLocation(); // Get current path
  const isHomePage = location.pathname === "/"; // Check if the current path is home

  return (
    <>
      {/* On homepage, show Scroller and Navbar with top 40px */}
      {isHomePage ? (
        <>
          <Scroller />
          <Navbar isHomePage={true} />
        </>
      ) : (
        // On other pages, only show Navbar
        <Navbar isHomePage={false} />
      )}
      {children}
    </>
  );
};
function App() {
  return (
    <div>
      <BrowserRouter>
        <div>
          <Layout />
        </div>
        <ScrollingUp/>
        {/* <Navbar/> */}
        <Routes>
          <Route path="/" element={<Shop />} />
          <Route
            path="/womens"
            element={<ShopCategory banner={women_banner} category_id={1} />}
          />
          <Route
            path="/home_decor"
            element={<ShopCategory banner={kid_banner} category_id={2} />}
          />
          <Route
            path="/garden_decor"
            element={<ShopCategory banner={kid_banner} category_id={3} />}
          />
          <Route path="/add_items" element={<AddItems />} />
          <Route path="/product" element={<Product />}>
            <Route path=":productId" element={<Product />} />
          </Route>

          <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/Login" element={<Login />} />
        </Routes>

        <Footer />
        <Analytics />
        <SpeedInsights />
      </BrowserRouter>
    </div>
  );
}

export default App;
