
import React, { useRef, useContext, useState } from "react";
import "./CartItems.css";
import { ShopContext } from "../../Context/ShopContext";
import remove_icon from "../Assets/cart_cross_icon.png";
import axios from "axios";
import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";

const CartItems = () => {
  const { getTotalCartAmount, cartItems, removeFromCart } =
    useContext(ShopContext);
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [Razorpay] = useRazorpay();

  // New state for form visibility and form data
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    delivery_address: "",
    name: "",
    country_code: "",
    mobile_number: "",
    pin_code: "",
    city: "",
    state: "",
    comment: "",
  });

  const formRef = useRef(null);
  const handleContinueClick = () => {
    // First, show the form
    setShowForm(true);

    // Then scroll to the form after it's visible
    setTimeout(() => {
      if (formRef.current) {
        formRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Add a small delay to ensure the form renders before scrolling
  };

  // Promo Code Handler
  const handlePromoCodeSubmit = async () => {
    const totalAmount = getTotalCartAmount();
    try {
      const response = await axios.post(
        "https://ecommerce-backend-mogr.onrender.com/api/validate-promo-code/",
        {
          amount: totalAmount,
          promo_code: promoCode,
        }
      );
      console.log("response");

      if (response?.data?.amount !== undefined && response.data.message) {
        setDiscount(response.data.amount);
        setMessage(response.data.message);
        setMessageType("success");
      } else {
        setMessage("Promo code not applied");
        setMessageType("error");
        setDiscount(0);
      }
    } catch (error) {
      setMessage("Promo code not applied");
      setMessageType("error");
      setDiscount(0);
    }
  };

  const [showNotification, setShowNotification] = useState(false); // For showing the notification

  const navigate = useNavigate();

  // Async function to verify payment
  const verify_payment = async (payment_id, order_id, signature) => {
    const totalAmount = getTotalCartAmount() - discount;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        "https://ecommerce-backend-mogr.onrender.com/api/verify-payment/",
        {
          razorpay_payment_id: payment_id,
          razorpay_order_id: order_id,
          razorpay_signature: signature,
          amount: totalAmount * 100,
        },
        {
          headers:{
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log("payment successful", response.data);
      setShowNotification(true); // Show the notification after payment success

      // Hide the notification after 5 seconds
      setTimeout(() => {
        setShowNotification(false);
        navigate("/");
        window.scrollTo(0, 0);
      }, 5000);
    } catch (error) {
        console.log("payment failed",error);
    }
  };

  // Checkout Handler (Razorpay Integration)
const handleCheckout = async (order_id) => {
  const totalAmount = getTotalCartAmount() - discount;
  try {
    if (order_id) {
      // console.log("Order created successfully with ID:", order_id);

      const options = {
        key: "rzp_test_CGyfBEXFXeiBsi", // Razorpay test key
        name: "Jaipur Masale & Dry Fruits",
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        order_id: order_id,
        handler: function (response) {
          verify_payment(
            response.razorpay_payment_id,
            response.razorpay_order_id,
            response.razorpay_signature
          );
        },
        prefill: {
          name: "Piyush Garg",
          email: "youremail@example.com",
          contact: "9999999999",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        console.error("Payment failed:", response.error);
      });

      rzp1.open(); // Open Razorpay payment modal
    } else {
      console.error("Failed to create order");
    }
  } catch (error) {
    console.error("Error during checkout:", error);
  }
};

// Handle form submission (create order)
const handleFormSubmit = async (event) => {
  event.preventDefault();
  const totalAmount = getTotalCartAmount() - discount;
  const promoCodeUsed = promoCode ? promoCode : "Nil";

  try {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    const res = await axios.post(
      "https://ecommerce-backend-mogr.onrender.com/api/create-order/",
      {
        amount: totalAmount * 100, // Amount in paisa (for INR)
        currency: "INR",
        promo_code: promoCodeUsed,
        ...formData, // Include form data (customer details)
      },
      {
        headers: {
          Authorization: `Token ${token}`, // Include token in Authorization header
        },
      }
    );

    // After order creation, pass order ID to handleCheckout
    const order_id = res.data.data.id;
    console.log("Order created successfully:", res.data.data);

    // Proceed to Razorpay checkout
    await handleCheckout(order_id);

  } catch (error) {
    console.error("Error during form submission:", error);
  }
};

  const finalAmount = getTotalCartAmount() - discount;

  return (
    <div className="cartitems">
      <div className="cartitems-format-main">
        <p>Products</p>
        <p>Title</p>
        <p>Price</p>
        <p>Size</p>
        <p>Quantity</p>
        <p>Total</p>
        <p>Remove</p>
      </div>
      <hr />
      {cartItems.length > 0 ? (
        cartItems.map((item) => (
          <div key={item.id}>
            <div className="cartitems-format cartitems-format-main">
              <img src={item.image} alt="" className="carticon-product-icon" />
              <p>{item.product_name}</p>
              <p>${item.offer_price}</p>
              <p>{item.size}</p>
              <button className="cartitems-quantity">{item.quantity}</button>
              <p>${item.offer_price * item.quantity}</p>
              <img
                className="cartitems-remove-icon"
                src={remove_icon}
                onClick={() =>
                  removeFromCart(item.id, item.size, item.offer_price, item.mrp)
                }
                alt="Remove"
              />
            </div>
            <hr />
          </div>
        ))
      ) : (
        <p>No items in the cart.</p>
      )}
      <div className="cartitems-down">
        <div className="cartitems-promocode">
          <p>If you have a promo code, enter it here</p>
          <div className="cartitems-promobox">
            <input
              type="text"
              placeholder="Promo code"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
            />
            <button onClick={handlePromoCodeSubmit}>Submit</button>
          </div>
          {message && (
            <p
              className={`cartitems-message ${
                messageType === "success" ? "success" : "error"
              }`}
            >
              {message}
            </p>
          )}
        </div>

        <div className="cartitems-total">
          <h1>Cart Totals</h1>
          <div>
            <div className="cartitems-total-item">
              <p>Subtotal</p>
              <p>${getTotalCartAmount()}</p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <p>Shipping Fee</p>
              <p>Free</p>
            </div>
            <hr />
            {discount > 0 && (
              <div className="cartitems-total-item">
                <p>Discount</p>
                <p>-${discount}</p>
              </div>
            )}
            <hr />
            <div className="cartitems-total-item">
              <h3>Total</h3>
              <h3>${finalAmount}</h3>
            </div>
          </div>
          <button onClick={handleContinueClick}>CLICK TO CONTINUE</button>
        </div>
      </div>

      {showForm && (
        <div
          ref={formRef}
          className="checkout-form w-full max-w-md mx-auto p-6 bg-white shadow-lg rounded-lg"
        >
          <h2>Fill the Delivery Information</h2>
          <form onSubmit={handleFormSubmit}>
            <input
              type="text"
              placeholder="Name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              required
            />
            <input
              type="text"
              placeholder="Delivery Address"
              value={formData.delivery_address}
              onChange={(e) =>
                setFormData({ ...formData, delivery_address: e.target.value })
              }
              required
            />
            <input
              type="text"
              placeholder="Country Code"
              value={formData.country_code}
              onChange={(e) =>
                setFormData({ ...formData, country_code: e.target.value })
              }
              required
            />
            <input
              type="text"
              placeholder="Mobile Number"
              value={formData.mobile_number}
              onChange={(e) =>
                setFormData({ ...formData, mobile_number: e.target.value })
              }
              required
            />
            <input
              type="text"
              placeholder="Pin Code"
              value={formData.pin_code}
              onChange={(e) =>
                setFormData({ ...formData, pin_code: e.target.value })
              }
              required
            />
            <input
              type="text"
              placeholder="City"
              value={formData.city}
              onChange={(e) =>
                setFormData({ ...formData, city: e.target.value })
              }
              required
            />
            <input
              type="text"
              placeholder="State"
              value={formData.state}
              onChange={(e) =>
                setFormData({ ...formData, state: e.target.value })
              }
              required
            />
            <textarea
              placeholder="Comments (optional)"
              value={formData.comment}
              onChange={(e) =>
                setFormData({ ...formData, comment: e.target.value })
              }
            />
            <button type="submit">PROCEED TO PAYMENT</button>
          </form>
        </div>
      )}
      {showNotification && (
        <div className="fixed top-8 right-4 bg-green-500 text-white p-4 rounded-lg shadow-lg">
          Your Order Placed successfully, You get invoice over Mail/Whatsapp.
        </div>
      )}
    </div>
  );
};

export default CartItems;

// latest import React, { useContext, useState } from 'react';
// import './CartItems.css';
// import { ShopContext } from '../../Context/ShopContext';
// import remove_icon from '../Assets/cart_cross_icon.png';
// import axios from 'axios';
// import useRazorpay from "react-razorpay";

// const CartItems = () => {
//     const { getTotalCartAmount, cartItems, removeFromCart } = useContext(ShopContext);
//     const [promoCode, setPromoCode] = useState('');
//     const [discount, setDiscount] = useState(0);
//     const [message, setMessage] = useState('');
//     const [messageType, setMessageType] = useState('');
//     const [Razorpay] = useRazorpay();

//     // Promo Code Handler
//     const handlePromoCodeSubmit = async () => {
//         const totalAmount = getTotalCartAmount();
//         try {
//             const response = await axios.post(
//                 'https://ecommerce-backend-mogr.onrender.com/api/validate-promo-code/',
//                 {
//                     amount: totalAmount,
//                     promo_code: promoCode
//                 }
//             );

//             if (response?.data?.amount !== undefined && response.data.message) {
//                 setDiscount(response.data.amount);
//                 setMessage(response.data.message);
//                 setMessageType('success');
//             } else {
//                 setMessage('Promo code not applied');
//                 setMessageType('error');
//                 setDiscount(0);
//             }
//         } catch (error) {
//             setMessage('Promo code not applied');
//             setMessageType('error');
//             setDiscount(0);
//         }
//     };

//     // Async function to verify payment
//     const verify_payment = async (payment_id, order_id, signature) => {
//         const totalAmount = getTotalCartAmount() - discount;
//         try {
//             const response = await axios.post(
//                 'https://ecommerce-backend-mogr.onrender.com/api/verify-payment/',
//                 {
//                     "razorpay_payment_id": payment_id,
//                     "razorpay_order_id": order_id,
//                     "razorpay_signature": signature,
//                     "amount": totalAmount * 100
//                 }
//             );
//             console.log("payment successfull",response.data);
//         } catch (error) {
//             console.log(error);
//         }
//     };

//     // Checkout Handler (Razorpay Integration)
//     const handleCheckout = async () => {
//         const totalAmount = getTotalCartAmount() - discount;
//         try {
//             const response = await axios.post(
//                 'https://ecommerce-backend-mogr.onrender.com/api/create-order/',
//                 {
//                     "amount": totalAmount * 100,
//                     "currency": "INR"
//                 }
//             );
//             if (response?.data?.data?.id) {
//                 console.log('Order created successfully:', response.data.data);
//                 const order_id =  response.data.data.id;

//                 const options = {
//                     key: "rzp_test_CGyfBEXFXeiBsi",
//                     name: "Acme Corp",
//                     description: "Test Transaction",
//                     image: "https://example.com/your_logo",
//                     order_id: order_id,
//                     handler: function (response) {
//                     //   alert(response.razorpay_payment_id);
//                     //   alert(response.razorpay_order_id);
//                     //   alert(response.razorpay_signature);
//                       verify_payment(
//                         response.razorpay_payment_id,
//                         response.razorpay_order_id,
//                         response.razorpay_signature
//                       )
//                     },
//                     prefill: {
//                       name: "Piyush Garg",
//                       email: "youremail@example.com",
//                       contact: "9999999999",
//                     },
//                     notes: {
//                       address: "Razorpay Corporate Office",
//                     },
//                     theme: {
//                       color: "#3399cc",
//                     },
//                   };

//                   const rzp1 = new Razorpay(options);

//                   rzp1.on("payment.failed", function (response) {
//                     alert(response.error.code);
//                     alert(response.error.description);
//                     alert(response.error.source);
//                     alert(response.error.step);
//                     alert(response.error.reason);
//                     alert(response.error.metadata.order_id);
//                     alert(response.error.metadata.payment_id);
//                   });

//                   rzp1.open();
//                 // Handle the success scenario - integrate Razorpay's frontend
//                 // window.location.href = `/payment?id=${response.data.id}`;
//             } else {
//                 console.error('Failed to create order');
//             }
//         } catch (error) {
//             console.error('Error during checkout:', error);
//         }
//     };

//     const finalAmount = getTotalCartAmount() - discount;

//     return (
//         <div className='cartitems'>
//             <div className="cartitems-format-main">
//                 <p>Products</p>
//                 <p>Title</p>
//                 <p>Price</p>
//                 <p>Size</p>
//                 <p>Quantity</p>
//                 <p>Total</p>
//                 <p>Remove</p>
//             </div>
//             <hr />
//             {cartItems.length > 0 ? (
//                 cartItems.map((item) => (
//                     <div key={item.id}>
//                         <div className="cartitems-format cartitems-format-main">
//                             <img src={item.image} alt="" className='carticon-product-icon' />
//                             <p>{item.product_name}</p>
//                             <p>${item.offer_price}</p>
//                             <p>{item.size}</p>
//                             <button className='cartitems-quantity'>{item.quantity}</button>
//                             <p>${item.offer_price * item.quantity}</p>
//                             <img
//                                 className='cartitems-remove-icon'
//                                 src={remove_icon}
//                                 onClick={() => removeFromCart(item.id,item.size)}
//                                 alt="Remove"
//                             />
//                         </div>
//                         <hr />
//                     </div>
//                 ))
//             ) : (
//                 <p>No items in the cart.</p>
//             )}
//             <div className="cartitems-down">
//                 <div className="cartitems-total">
//                     <h1>Cart Totals</h1>
//                     <div>
//                         <div className="cartitems-total-item">
//                             <p>Subtotal</p>
//                             <p>${getTotalCartAmount()}</p>
//                         </div>
//                         <hr />
//                         <div className="cartitems-total-item">
//                             <p>Shipping Fee</p>
//                             <p>Free</p>
//                         </div>
//                         <hr />
//                         {discount > 0 && (
//                             <div className="cartitems-total-item">
//                                 <p>Discount</p>
//                                 <p>-${discount}</p>
//                             </div>
//                         )}
//                         <hr />
//                         <div className="cartitems-total-item">
//                             <h3>Total</h3>
//                             <h3>${finalAmount}</h3>
//                         </div>
//                     </div>
//                     <button onClick={handleCheckout}>PROCEED TO CHECKOUT</button>
//                 </div>
//                 <div className="cartitems-promocode">
//                     <p>If you have a promo code, enter it here</p>
//                     <div className="cartitems-promobox">
//                         <input
//                             type="text"
//                             placeholder='Promo code'
//                             value={promoCode}
//                             onChange={(e) => setPromoCode(e.target.value)}
//                         />
//                         <button onClick={handlePromoCodeSubmit}>Submit</button>
//                     </div>
//                     {message && (
//                         <p className={`cartitems-message ${messageType === 'success' ? 'success' : 'error'}`}>
//                             {message}
//                         </p>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default CartItems;

// import React, { useContext, useState } from 'react';
// import './CartItems.css';
// import { ShopContext } from '../../Context/ShopContext';
// import remove_icon from '../Assets/cart_cross_icon.png';
// import axios from 'axios';

// const CartItems = () => {
//     const { getTotalCartAmount, cartItems, removeFromCart } = useContext(ShopContext);
//     const [promoCode, setPromoCode] = useState('');
//     const [discount, setDiscount] = useState(0);
//     const [message, setMessage] = useState('');
//     const [messageType, setMessageType] = useState(''); // To store the type of message (success or error)

//     const handlePromoCodeSubmit = async () => {
//         const totalAmount = getTotalCartAmount();
//         try {
//             const response = await axios.post(
//                 'https://ecommerce-backend-iota-weld.vercel.app/api/validate-promo-code/',
//                 {
//                     amount: totalAmount,
//                     promo_code: promoCode
//                 }
//             );

//             // Check if the response is valid and contains the necessary data
//             if (response && response.data && response.data.amount !== undefined && response.data.message) {
//                 setDiscount(response.data.amount); // Set the discount received from the API
//                 setMessage(response.data.message); // Set the message
//                 setMessageType('success'); // Set the message type to success

//             } else {

//                 setMessage('Promo code not applied');
//                 setMessageType('error'); // Set the message type to error
//                 setDiscount(0);

//             }
//         } catch (error) {
//             // Handle any error from the API call
//             setMessage('Promo code not applied');
//             setMessageType('error'); // Set the message type to error
//             setDiscount(0);

//         }
//     };

//     const finalAmount = getTotalCartAmount() - discount; // Calculate the final amount after discount

//     return (
//         <div className='cartitems'>
//             <div className="cartitems-format-main">
//                 <p>Products</p>
//                 <p>Title</p>
//                 <p>Price</p>
//                 <p>Size</p>
//                 <p>Quantity</p>
//                 <p>Total</p>
//                 <p>Remove</p>
//             </div>
//             <hr />
//             {cartItems.length > 0 ? (
//                 cartItems.map((item) => (
//                     <div key={item.id}>
//                         <div className="cartitems-format cartitems-format-main">
//                             <img src={item.image} alt="" className='carticon-product-icon' />
//                             <p>{item.product_name}</p>
//                             <p>${item.offer_price}</p>
//                             <p>{item.size}</p>
//                             <button className='cartitems-quantity'>{item.quantity}</button>
//                             <p>${item.offer_price * item.quantity}</p>
//                             <img
//                                 className='cartitems-remove-icon'
//                                 src={remove_icon}
//                                 onClick={() => removeFromCart(item.id,item.size)}
//                                 alt="Remove"
//                             />
//                         </div>
//                         <hr />
//                     </div>
//                 ))
//             ) : (
//                 <p>No items in the cart.</p>
//             )}
//             <div className="cartitems-down">
//                 <div className="cartitems-total">
//                     <h1>Cart Totals</h1>
//                     <div>
//                         <div className="cartitems-total-item">
//                             <p>Subtotal</p>
//                             <p>${getTotalCartAmount()}</p>
//                         </div>
//                         <hr />
//                         <div className="cartitems-total-item">
//                             <p>Shipping Fee</p>
//                             <p>Free</p>
//                         </div>
//                         <hr />
//                         {discount > 0 && (
//                             <div className="cartitems-total-item">
//                                 <p>Discount</p>
//                                 <p>-${discount}</p>
//                             </div>
//                         )}
//                         <hr />
//                         <div className="cartitems-total-item">
//                             <h3>Total</h3>
//                             <h3>${finalAmount}</h3>
//                         </div>
//                     </div>
//                     <button>PROCEED TO CHECKOUT</button>
//                 </div>
//                 <div className="cartitems-promocode">
//                     <p>If you have a promo code, enter it here</p>
//                     <div className="cartitems-promobox">
//                         <input
//                             type="text"
//                             placeholder='Promo code'
//                             value={promoCode}
//                             onChange={(e) => setPromoCode(e.target.value)}
//                         />
//                         <button onClick={handlePromoCodeSubmit}>Submit</button>
//                     </div>
//                     {message && (
//                         <p className={`cartitems-message ${messageType === 'success' ? 'success' : 'error'}`}>
//                             {message}
//                         </p>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default CartItems;
