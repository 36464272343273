// import React, { useContext } from 'react'
// import './CSS/ShopCategory.css'
// import { ShopContext } from '../Context/ShopContext'
// import dropdown_icon from '../Components/Assets/dropdown_icon.png'
// import Item from '../Components/Item/Item'

// const ShopCategory = (props) => {
//   const {all_product} = useContext(ShopContext);
//   return (
//     <div className='shop-category'>
//       <img className='shopcategory-banner' src={props.banner} alt="" />
//       <div className="shopcategory-indexSort">
//         <p>
//           <span>Showing 1-12</span> out of 36 products
//         </p>
//         <div className="shopcategory-sort">
//           Sort by <img src={dropdown_icon} alt="" />
//         </div>
//       </div>
//       <div className="shopcategory-products">
//         {all_product.map((item,i)=>{
//           if (props.category===item.category) {
//             return <Item key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price}/>
//           }
//           else{
//             return null;
//           }
//         })}
//       </div>
//       <div className="shopcategory-loadmore">
//         Explore More
//       </div>
//     </div>
//   )
// }

// export default ShopCategory


import React, { useState, useEffect } from 'react';
import './CSS/ShopCategory.css';
import axios from 'axios';
import Item from '../Components/Item/Item';
import dropdown_icon from '../Components/Assets/dropdown_icon.png';
import ContactUs from '../Components/ContactUs/ContactUs'

const ShopCategory = ({ banner, category_id }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchCategoryProducts = async () => {
      try {
        const response = await axios.get(`https://ecommerce-backend-mogr.onrender.com/api/get-all-products/`, {
          params: { category_pk: category_id }
        });
        setProducts(response.data.data);
      } catch (error) {
        console.error('Error fetching category products:', error);
      }
    };

    fetchCategoryProducts();
  }, [category_id]);

  return (
    <div className='shop-category'>
      <img className='shopcategory-banner' src={banner} alt="" />
      <div className="shopcategory-indexSort">
        <p>
          <span>Showing 1-12</span> out of {products.length} products
        </p>
        <div className="shopcategory-sort">
          Sort by <img src={dropdown_icon} alt="" />
        </div>
      </div>
      <div className="shopcategory-products">
        {products
          .filter(item => item.is_active)
          .map((item) => (
            <Item
              key={item.id}
              id={item.id}
              name={item.product_name}
              image={item.main_image}
              new_price={item.offer_price}
              old_price={item.mrp}
              rating={item.rating}
            />
          ))}
      </div>
      <div className="shopcategory-loadmore">
        Explore More
      </div>
      <ContactUs/>
    </div>
  );
};

export default ShopCategory;


