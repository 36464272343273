import {
  CustomerServiceOutlined,
  RightOutlined,
  SafetyCertificateFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Typography } from "antd";
import "./Query.css";

export default function Query() {
  return (
    <div className="query-page-main">
      <h1 className="query-title">WHY US</h1>
      <hr/>
      <div className="query-description">
      <Row>
        <Col lg={1} xs={2}></Col>
        <Col lg={22} xs={20}>
          <Row>
            <Col lg={11} xs={24}>
              <div>
                <Typography className="query-section-title">
                  Why You Should Buy From Ditto
                </Typography>
                <Typography className="query-text">
                  If you're still reading this, we are pretty sure you already
                  recognize the benefit of using Ditto. However, if you are
                  still not convinced, here's a few extra points to change your
                  mind
                </Typography>
                <div className="listed-text">
                  <ul>
                    <li>Chat Support for additional queries</li>
                    <li>Claim Support for when you have to make a claim</li>
                    <li>Moral Support for when you need reassurance</li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={2} xs={1}></Col>
            <Col lg={9} xs={22}>
              <div style={{ display:"flex",flexDirection:"column",gap:25 }}>
                <div>
                  <Card>
                    <div className="rightside-card">
                      <div className="rightside-icon">
                        <SafetyCertificateFilled
                          style={{ color: "#5e5ee9", fontSize: 26 }}
                        />
                      </div>
                      <div>
                        <Typography.Title level={3} className="rightside-card-title">
                          Buy Insurance
                        </Typography.Title>
                        <Typography className="card-description">
                          Use our intuitive platform to understand, compare &
                          buy a comprehensive plan
                        </Typography>
                      </div>
                      <div className="rightside-secoend-icon">
                        <RightOutlined style={{ fontSize: 26 }} />
                      </div>
                    </div>
                  </Card>
                </div>
                <div>
                  <Card>
                    <div className="rightside-card">
                      <div className="rightside-icon">
                        <CustomerServiceOutlined
                          style={{ color: "#5e5ee9", fontSize: 26 }}
                        />
                      </div>
                      <div>
                        <Typography.Title level={3} className="rightside-card-title">
                        Ditto Hotline
                        </Typography.Title>
                        <Typography className="card-description">
                        If you need personalized advice, book a call with the best advisors in town
                        </Typography>
                      </div>
                      <div className="rightside-secoend-icon">
                        <RightOutlined style={{ fontSize: 26 }} />
                      </div>
                    </div>
                  </Card>
                </div>
                <div>
                  <Card>
                    <div className="rightside-card">
                      <div className="rightside-icon">
                        <SearchOutlined
                          style={{ color: "#5e5ee9", fontSize: 26 }}
                        />
                      </div>
                      <div>
                        <Typography.Title level={3} className="rightside-card-title">
                        Understand your Insurance
                        </Typography.Title>
                        <Typography className="card-description">
                        If you already have a health plan, you can see what it's lacking here
                        </Typography>
                      </div>
                      <div className="rightside-secoend-icon">
                        <RightOutlined style={{ fontSize: 26 }} />
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={1} xs={2}></Col>
      </Row>
      </div>
    </div>
  );
}
